var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formValidation"},[_c('b-form',[(_vm.id === undefined)?_c('b-row',{staticClass:"align-content-center mx-auto d-flex flex-column"},[_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('h5',[_vm._v("Datos del usaurio")])]),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Primer nombre","label-for":"first_name"}},[_c('validation-provider',{attrs:{"name":"primer nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_name","state":errors.length > 0 ? false:null,"placeholder":"Primer nombre"},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3833148020)})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Segundo nombre","label-for":"last_name"}},[_c('validation-provider',{attrs:{"name":"segundo nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last_name","state":errors.length > 0 ? false:null,"placeholder":"Segundo nombre"},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2619097284)})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email_user"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"email","placeholder":"Email"},model:{value:(_vm.email_user),callback:function ($$v) {_vm.email_user=$$v},expression:"email_user"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,827430155)})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Teléfono","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"teléfono","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"number","placeholder":"teléfono"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1555396330)})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',[_vm._v("Contraseña")])]),_c('validation-provider',{attrs:{"name":"contraseña","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"type":_vm.passwordFieldType,"name":"password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,407048702)})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',[_vm._v("Confirmación de contraseña")])]),_c('validation-provider',{attrs:{"name":"confirmación de contraseña","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"type":_vm.passwordFieldType,"name":"password_confirmation","placeholder":"············"},model:{value:(_vm.password_confirmation),callback:function ($$v) {_vm.password_confirmation=$$v},expression:"password_confirmation"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2057920542)})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('hr')])],1):_vm._e(),_c('b-row',{staticClass:"align-content-center mx-auto d-flex flex-column"},[_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('h5',[_vm._v("Datos de la Compañía")])]),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nombre de la companía","label-for":"name_company"}},[_c('validation-provider',{attrs:{"name":"nombre de la companía","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name_company","state":errors.length > 0 ? false:null,"placeholder":"Nombre de la companía"},model:{value:(_vm.name_company),callback:function ($$v) {_vm.name_company=$$v},expression:"name_company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Email de la companía","label-for":"email_company"}},[_c('validation-provider',{attrs:{"name":"email de la companía","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"email","placeholder":"Email de la companía"},model:{value:(_vm.email_company),callback:function ($$v) {_vm.email_company=$$v},expression:"email_company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Teléfono de la compañía","label-for":"phone_company"}},[_c('validation-provider',{attrs:{"name":"teléfono de la compañía","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"number","placeholder":"teléfono de la compañía"},model:{value:(_vm.phone_company),callback:function ($$v) {_vm.phone_company=$$v},expression:"phone_company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Role","label-for":"role"}},[_c('validation-provider',{attrs:{"name":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.id === undefined)?_c('v-select',{attrs:{"dir":'ltr',"label":"name","state":errors.length > 0 ? false : null,"options":_vm.rowsRoles,"select-size":6},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("No hay data para cargar")])]):_vm._e(),(_vm.id !== undefined && _vm.accessPermissions('company.assign.roles'))?_c('v-select',{attrs:{"dir":'ltr',"label":"name","multiple":"","state":errors.length > 0 ? false : null,"options":_vm.rowsRoles,"select-size":6},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("No hay data para cargar")])]):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"10","sm":"12"}},[(_vm.id === undefined ? _vm.accessPermissions('company.store') : _vm.accessPermissions('company.update'))?_c('b-button',{staticClass:"float-right text-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.onHandleValidationForm.apply(null, arguments)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_vm._v(" Guardar ")],1):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"float-right mr-1",attrs:{"variant":"secondary","to":{ name: 'settings-companies' }}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }