export default {
  indexAssignedEndpoint: '/roles-assigned',
  indexEndpoint: '/roles',
  permissionsByRole: '/roles-permissions/',
  storeEndpoint: '/roles',
  editEndpoint: '/roles/',
  updateEndpoint: '/roles/',
  deleteEndpoint: '/roles/',
  permissionsAssignByRole: '/roles-assign-permissions/',
}
