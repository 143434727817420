import companiesDefaultConfig from './CompaniesDefaultConfig'
import axiosIns from '@/libs/axios'

class CompaniesServices {
    config = { ...companiesDefaultConfig }

    axios = null

    constructor() {
        this.axios = axiosIns
    }

    async handleList() {
        const result = await this.axios.get(this.config.indexEndpoint)

        return result
    }

    async handleStore(data) {
        const result = await this.axios.post(this.config.storeEndpoint, data)

        return result
    }

    async handleEdit(id) {
        const result = await this.axios.get(`${this.config.editEndpoint}${id}`)

        return result
    }

    async handleUpdate(id, data) {
        const result = await this.axios.put(`${this.config.updateEndpoint}${id}`, data)

        return result
    }

    async handleAssignRole(id, data) {
        const result = await this.axios.put(`${this.config.assignRoleEndpoint}${id}`, data)

        return result
    }

    async handleDelete(id) {
        const result = await this.axios.delete(`${this.config.deleteEndpoint}${id}`)

        return result
    }
}

export default new CompaniesServices()
