<template>
  <validation-observer ref="formValidation">
    <b-form>
      <b-row
        v-if="id === undefined"
        class="align-content-center mx-auto d-flex flex-column"
      >
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <h5>Datos del usaurio</h5>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Primer nombre"
            label-for="first_name"
          >
            <validation-provider
              #default="{ errors }"
              name="primer nombre"
              rules="required"
            >
              <b-form-input
                id="first_name"
                v-model="first_name"
                :state="errors.length > 0 ? false:null"
                placeholder="Primer nombre"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Segundo nombre"
            label-for="last_name"
          >
            <validation-provider
              #default="{ errors }"
              name="segundo nombre"
              rules="required"
            >
              <b-form-input
                id="last_name"
                v-model="last_name"
                :state="errors.length > 0 ? false:null"
                placeholder="Segundo nombre"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Email"
            label-for="email_user"
          >
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-form-input
                v-model="email_user"
                :state="errors.length > 0 ? false:null"
                type="email"
                placeholder="Email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Teléfono"
            label-for="phone"
          >
            <validation-provider
              #default="{ errors }"
              name="teléfono"
              rules="required"
            >
              <b-form-input
                v-model="phone"
                :state="errors.length > 0 ? false:null"
                type="number"
                placeholder="teléfono"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group>
            <div class="d-flex justify-content-between">
              <label>Contraseña</label>
            </div>
            <validation-provider
              #default="{ errors }"
              name="contraseña"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
              >
                <b-form-input
                  v-model="password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group>
            <div class="d-flex justify-content-between">
              <label>Confirmación de contraseña</label>
            </div>
            <validation-provider
              #default="{ errors }"
              name="confirmación de contraseña"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
              >
                <b-form-input
                  v-model="password_confirmation"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="password_confirmation"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <hr>
        </b-col>
      </b-row>
      <b-row class="align-content-center mx-auto d-flex flex-column">
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <h5>Datos de la Compañía</h5>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Nombre de la companía"
            label-for="name_company"
          >
            <validation-provider
              #default="{ errors }"
              name="nombre de la companía"
              rules="required"
            >
              <b-form-input
                id="name_company"
                v-model="name_company"
                :state="errors.length > 0 ? false:null"
                placeholder="Nombre de la companía"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Email de la companía"
            label-for="email_company"
          >
            <validation-provider
              #default="{ errors }"
              name="email de la companía"
              rules="required|email"
            >
              <b-form-input
                v-model="email_company"
                :state="errors.length > 0 ? false:null"
                type="email"
                placeholder="Email de la companía"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Teléfono de la compañía"
            label-for="phone_company"
          >
            <validation-provider
              #default="{ errors }"
              name="teléfono de la compañía"
              rules="required"
            >
              <b-form-input
                v-model="phone_company"
                :state="errors.length > 0 ? false:null"
                type="number"
                placeholder="teléfono de la compañía"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Role"
            label-for="role"
          >
            <validation-provider
              #default="{ errors }"
              name="role"
              rules="required"
            >
              <v-select
                v-if="id === undefined"
                v-model="role"
                :dir="'ltr'"
                label="name"
                :state="errors.length > 0 ? false : null"
                :options="rowsRoles"
                :select-size="6"
              >
                <span slot="no-options">No hay data para cargar</span>
              </v-select>
              <v-select
                v-if="id !== undefined && accessPermissions('company.assign.roles')"
                v-model="role"
                :dir="'ltr'"
                label="name"
                multiple
                :state="errors.length > 0 ? false : null"
                :options="rowsRoles"
                :select-size="6"
              >
                <span slot="no-options">No hay data para cargar</span>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-button
            v-if="id === undefined ? accessPermissions('company.store') : accessPermissions('company.update')"
            class="float-right text-right"
            variant="primary"
            type="submit"
            @click.prevent="onHandleValidationForm"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="float-right mr-1"
            :to="{ name: 'settings-companies' }"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required, email } from '@validations'
import router from '@/router'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import RolesServices from '@/pages/dashboard/settings/roles/services/RolesServices'
import CompaniesServices from '../services/CompaniesServices'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'FormCreateComponent',
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BButton,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      first_name: '',
      last_name: '',
      email_user: '',
      phone: '',
      role: '',
      rowsRoles: [],
      password: '',
      password_confirmation: '',
      name_company: '',
      email_company: '',
      phone_company: '',
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  async created() {
    await this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    clearForm() {
      this.first_name = ''
      this.last_name = ''
      this.email_user = ''
      this.phone = ''
      this.role = ''
      this.rowsRoles = []
      this.password = ''
      this.password_confirmation = ''
      this.name_company = ''
      this.email_company = ''
      this.phone_company = ''
      this.$refs.formValidation.reset()
    },
    async onHandleEdit() {
      await this.onHandleRolesList()

      if (this.id !== undefined && this.accessPermissions('company.show')) {
        await CompaniesServices.handleEdit(this.id).then(response => {
          const array = []

          if (response.data.data.roles.length > 0) {
            response.data.data.roles.map(({ id, name_public }) => {
              array.push({ id: id, name: name_public })
            })
          }

          this.name_company = response.data.data.name_company
          this.email_company = response.data.data.email_company
          this.phone_company = response.data.data.phone_company
          this.role = array
        }).catch(error => this.errorResp(error))
      }
    },
    async onHandleRolesList() {
      await RolesServices.handleList().then(response => {
        const array = []

        if (response.data.data.length > 0) {
          response.data.data.map(({ id, name_public }) => {
            array.push({ id: id, name: name_public })
          })
        }

        this.rowsRoles = array
      }).catch(error => this.errorResp(error))
    },
    async onHandleValidationForm() {
      await this.$refs.formValidation.validate().then(success => {
        if (success) {
          if (this.id === undefined && this.accessPermissions('company.store')) {
            const data = {
              first_name: this.first_name,
              last_name: this.last_name,
              email: this.email_user,
              phone: this.phone,
              role: this.role.id,
              password: this.password,
              password_confirmation: this.password_confirmation,
              name_company: this.name_company,
              email_company: this.email_company,
              phone_company: this.phone_company,
              dni_type: 1,
              dni_profile: 1,
            }

            CompaniesServices.handleStore(data).then(response => {
              if (response.data.data) {
                this.swal('La compañía ha sido rsgistrada correctamente', 'success')
                this.clearForm()
                router.push({ name: 'settings-companies-edit', params: { id: response.data.data.id } })
              }
            }).catch(error => this.errorResp(error))
          } else if (this.id !== undefined && this.accessPermissions('company.update')) {
            const roles = []

            if (this.role.length > 0) {
              this.role.map(({ id }) => {
                roles.push(id)
              })
            }

            const data = {
              name_company: this.name_company,
              email_company: this.email_company,
              phone_company: this.phone_company,
              role: roles,
            }

            CompaniesServices.handleUpdate(this.id, data).then(response => {
              if (response.data.data) {
                this.swal('La compañía ha sido actualizada correctamente', 'success')
                this.clearForm()
                router.push({ name: 'settings-companies' })
              }
            }).catch(error => this.errorResp(error))
          }
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
